import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A poster was presented at the 2021 Netherlands Earth Science Congress (held virtually) under the title `}<strong parentName="p">{`The lost 'continents' of the South Atlantic Ocean`}</strong>{`. The poster may be viewed with the following link:`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/NAC2021-CVR-final.pdf"
      }}>{`Reeves & Souza, 2021`}</a></p>
    <p>{`An animation demonstrating the concept behind the poster may be viewed as this link:`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/RioGrande-5-1-anigif.gif"
      }}>{`Rio Grande Ridge animation`}</a></p>
    <p><em parentName="p">{`Last updated: 2021 May 3`}</em></p>
    <p><a parentName="p" {...{
        "href": "../other-material"
      }}>{`Return to Global Thoughts page`}</a></p>
    <p><a parentName="p" {...{
        "href": "../../gondwana"
      }}>{`Return to Gondwana main page`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      